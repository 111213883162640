<style>

</style>

<script>
export default {
  name: "BanLinkUpload",
  props: ['banLinks', 'createMode'],
  methods: {
    addLink() {
      let link = String(this.$refs.banFormLinks.value);
      if (!link.length) return;
      if (!link.startsWith('http://') && !link.startsWith('https://')) return;
      this.banLinks.push(link);
      this.$refs.banFormLinks.value = '';
    },
    removeLink(link) {
      let index = this.banLinks.indexOf(link);
      if (index !== -1) this.banLinks.splice(index, 1);
    },
  },
}
</script>
<template>
  <div>
    <hr v-if="createMode" style="margin-top: 14px">
    <p v-if="createMode">{{ $t('banmanager.add.evidence.link') }}</p>
    <div class="row mt-1">
      <div class="col">
        <div class="form-group">
          <div class="input-group mb-3">
            <input
                id="banFormLinks"
                ref="banFormLinks"
                :placeholder="$t('banmanager.add.evidence.link')"
                class="form-control form-control-sm"
                type="text"
            />
            <div class="input-group-append">
              <button class="btn btn-sm btn-primary d-inline-block" data-toggle="modal" v-on:click="addLink()">
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ul class="ml-0 pl-0 mt-2" style="list-style: none;">
          <li v-for="link in banLinks" :key="link" class="text-truncate">
            <i class="fas fa-times-hexagon text-danger" style="cursor: pointer;"
               v-on:click="removeLink(link)"></i>
            <span class="ml-2 text-small">
              <a :href="link" target="_blank">{{ link }}</a>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>